import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { PartsApi } from "../api/PartsApi";
import { PageHeader } from '../components/shared/PageHeader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCalendarDays, faChartSimple } from '@fortawesome/free-solid-svg-icons';

import CreatableSelect from 'react-select/creatable';

export const PartSearch = ({ theme }) => {

    const navigate = useNavigate(); 
    const routeChange = (niin) =>{ 
        let path = `view/${niin}`; 
        navigate(path);
    }
    const [niinInputValue, setNiinInputValue] = React.useState('');
    const [niinListValue, setNiinListValue] = React.useState([]);

    const [pnInputValue, setPnInputValue] = React.useState('');
    const [pnListValue, setPnListValue] = React.useState([]);

    const [cageInputValue, setCageInputValue] = React.useState('');
    const [cageListValue, setCageListValue] = React.useState([]);

    const [partHeaderRows, setPartHeaderRows] = useState([]);
    const [currentTheme, setCurrentTheme] = useState([]);

    const [searchDisabled, setSearchDisabled] = useState(false);

    const createOption = (label) => ({
        key: label,
        label,
        value: label
    });

    const handleNiinInputKeyDown = (event) => {
        if (!niinInputValue) return;
        let niinPieces = niinInputValue.split("-")
        if (niinPieces.length === 4) niinPieces.shift();
        else if (niinPieces.length === 1) {
            if (niinPieces[0].length === 13) {
                niinPieces[0] = niinPieces[0].substring(4);
            }
        }

        let niinInputClean = niinPieces.join('');

        //if (niinInputClean.length > 9) alert("Only NIIN's are allowed.");
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setNiinListValue((prev) => [...prev, createOption(niinInputClean)]);
                setNiinInputValue('');
                event.preventDefault();
        }
    };

    const handlePnInputKeyDown = (event) => {
        if (!pnInputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setPnListValue((prev) => [...prev, createOption(pnInputValue)]);
                setPnInputValue('');
                event.preventDefault();
        }
    };

    const getPartHeaderRows = () => {
        if (niinListValue.length > 0 || pnListValue.length > 0) {
            return new Promise((resolve, reject) => {
                PartsApi.search(
                    {
                        niins: niinListValue.map(a => a.value).join(","),
                        partNumbers: pnListValue.map(a => a.value).join(",")
                    }
                )
                    .then(response => {
                        console.log(JSON.stringify(response.data));
                        setPartHeaderRows(response.data);
                        setNiinInputValue('');
                        resolve();
                    })
                    .catch(error => {
                        console.log(error);
                        reject();
                    })

            });
        }
    }

    const partHeaderColumns = [
        {
            name: 'NIIN',
            selector: row => row.niin,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => routeChange(row.niin)}>{row.niin}</a>
            </>
        },

        {
            name: 'FSC',
            selector: row => row.fsc,
        },

        {
            name: 'Item Name',
            selector: row => row.nomenclature,
        },

        {
            name: 'Replacement',
            selector: row => row.replacement_niin,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => routeChange(row.replacement_niin)}>{row.replacement_niin}</a>
            </>
        }
    ];

    const customStylesDark = {
        table: {
            style: {
                fontSize: '12px'
            }
        },
        header: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        headRow: {

            style: {
                padding: "6px 8px 6px 8px",
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        rows: {
            style: {
                padding: "6px 8px 6px 8px",
                '&:nth-of-type(even)': {
                    color: 'white',
                    backgroundColor: '#1D2839'
                },
                '&:nth-of-type(odd)': {
                    color: 'white',
                    backgroundColor: '#39445C'
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
                borderTop: '1px solid white'
            }
        }
    };

    const customStylesLight = {

        header: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        rows: {
            style: {
                '&:nth-of-type(even)': {
                    color: 'black',
                    backgroundColor: '#e8e8e8'
                },
                '&:nth-of-type(odd)': {
                    color: 'black',
                    backgroundColor: 'white'
                },
            },
        }
    };

    return (
        <>
            <PageHeader pageName= {"Part Search"} />

            <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                <div className="m-0 p-0 col-12 mb-4 card-element" id="graph-bounding-box">
                    <div id="accordian">
                        <div className="card">

                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Search Criteria</span>
                                </h5>
                            </div>
                            
                            <div className="card-header p-0" id="headingOne">
                                <h6 className="mb-0">
                                    <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{ padding: '0' }} >
                                        <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />
                                    </button>

                                    <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '60px' }}>NIINs:</span>

                                    {niinListValue.map((o, i) =>
                                        <span className="badge badge-pill badge-secondary" style={{ fontWeight: 'normal', fontSize: '.7rem', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }}>{o.label}</span>
                                    )}
                                </h6>
                            </div>

                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordian">
                                <div className="card-body accordian">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">NIINs</label>
                                            <CreatableSelect
                                                components={{
                                                    DropdownIndicator: null
                                                }}
                                                inputValue={niinInputValue}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={(newValue) => setNiinListValue(newValue)}
                                                onInputChange={(newValue) => setNiinInputValue(newValue)}
                                                onKeyDown={handleNiinInputKeyDown}
                                                placeholder="Digits only, then press enter ..."
                                                value={niinListValue}
                                                key="niin-list"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card-header p-0" id="headingTwo">
                                <h6 className="mb-0">
                                    <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ padding: '0' }} >
                                        <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />
                                    </button>

                                    <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '120px' }}>Part Numbers:</span>

                                    {pnListValue.map((o, i) =>
                                        <span className="badge badge-pill badge-secondary" style={{ fontWeight: 'normal', fontSize: '.7rem', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }}>{o.label}</span>
                                    )}
                                </h6>
                            </div>

                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordian">
                                <div className="card-body accordian">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">Part Numbers</label>
                                            <CreatableSelect
                                                components={{
                                                    DropdownIndicator: null
                                                }}
                                                inputValue={pnInputValue}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={(newValue) => setPnListValue(newValue)}
                                                onInputChange={(newValue) => setPnInputValue(newValue)}
                                                onKeyDown={handlePnInputKeyDown}
                                                placeholder=""
                                                value={pnListValue}
                                                key="pn-list"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className=" px-2" style={{ padding: '0', verticalAlign: 'middle' }} >
                                        <FontAwesomeIcon icon={faAngleRight} style={{ width: '20px' }} />
                                    </span>

                                    <button className="btn btn-sm btn-primary my-2" onClick={() => getPartHeaderRows()} disabled={searchDisabled}>Load Data</button>
                                </h5>
                            </div>

                        </div>
                    </div>


                </div>

                <div className="m-0 p-0 col-12 mb-4 card-element" id="graph-bounding-box-2" style={{ minHeight: '300px' }}>
                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                        <div className="card" style={{ border: '0' }}>

                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Results</span>
                                </h5>
                            </div>

                        </div>


                    </div>

                    <DataTable
                        columns={partHeaderColumns}
                        data={partHeaderRows}
                        customStyles={customStylesDark}
                        pagination
                        dense
                    />
                </div>

            </div>
        </>
    )
}