import axios from 'axios'
import { addInterceptor } from "./addInterceptor";

const apiBase = axios.create({
    baseURL: `/api/v1/parts`
})

addInterceptor(apiBase)

export const PartsApi = {
    search: (queryParams) => {
        return apiBase.get('search', { params: queryParams });
    },

    view: (niin) => {
        return apiBase.get(`view/${niin}`);
    },

    searchWsdc: (niin) => {
        return apiBase.get(`view/${niin}/wsdc`);
    },

    searchMoe: (niin) => {
        return apiBase.get(`view/${niin}/moe`);
    },

    searchSolicitationHistory: (niin) => {
        return apiBase.get(`view/${niin}/solicitation_history`);
    },

    searchStockOnHand: (niin) => {
        return apiBase.get(`view/${niin}/stock_on_hand`);
    },

    searchReplacements: (niin, predecessor) => {
        console.log(niin)
        console.log(predecessor)
        return apiBase.get(`view/${niin}/substitutes`, {
            params: {
                predecessors: predecessor
            }
        });
    },

    searchManagement: (niin) => {
        return apiBase.get(`view/${niin}/management`);
    },

    searchStockData: (niin) => {
        return apiBase.get(`view/${niin}/stock`);
    },

    searchItemClassification: (niin) => {
        return apiBase.get(`view/${niin}/classification`);
    },

    searchFreightAndDimensions: (niin) => {
        return apiBase.get(`view/${niin}/freight_dimensions`);
    }
};