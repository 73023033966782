import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { PartsApi } from "../api/PartsApi";
import { PageHeader } from '../components/shared/PageHeader';
import { Tabs } from '../components/Tabs';

import { MoeTable } from '../components/parts/MoeTable';
import { SolicitationHistoryTable } from '../components/parts/SolicitationHistoryTable';
import { WsdcTable } from '../components/parts/WsdcTable';
import { WsdcCommonNameTable } from '../components/parts/WsdcCommonNameTable';
import { ReplacedNiinsTable } from '../components/parts/ReplacedNiinsTable';
import { ManagementTable } from '../components/parts/ManagementTable';

import { PartStockOnHand } from '../components/parts/Charts/PartStockOnHand';
import { Graph } from '../components/reports/Charts/AreaChartTest';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCalendarDays, faChartSimple, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import BeatLoader from 'react-spinners/BeatLoader';
import GridLoader from 'react-spinners/GridLoader';

import CreatableSelect from 'react-select/creatable';
import { CurrencyFormatter, NumberFormatter } from "../utils/NumberFormatter";

export const PartDetail = ({ theme }) => {
    const navigate = useNavigate();
    const routeChange = (niin) => {
        let path = `view/${niin}`;
        navigate(path);
    }

    const [part, setPart] = React.useState(null);
    const [dataLoading, setDataLoading] = React.useState(true);

    const [wsdcDetailRows, setWsdcDetailRows] = React.useState([]);
    const [wsdcDetailRowsLoading, setWsdcDetailRowsLoading] = React.useState(false);

    const [moeDetailRows, setMoeDetailRows] = React.useState([]);
    const [moeDetailRowsLoading, setMoeDetailRowsLoading] = React.useState(false);

    const [solicitationHistoryRows, setSolicitationHistoryRows] = React.useState([]);
    const [solicitationHistoryRowsLoading, setSolicitationHistoryRowsLoading] = React.useState(false);

    const [stockOnHandRows, setStockOnHandRows] = React.useState([]);
    const [stockOnHandRowsLoading, setStockOnHandRowsLoading] = React.useState(false);

    const [replacementNiinRows, setReplacementNiinRows] = React.useState([]);
    const [replacementNiinRowsLoading, setReplacementNiinRowsLoading] = React.useState(false);

    const [managementDataRows, setManagementDataRows] = React.useState([]);
    const [managementDataRowsLoading, setManagementDataRowsLoading] = React.useState(false);

    const [stockData, setStockData] = React.useState(null);
    const [stockDataLoading, setStockDataLoading] = React.useState(false);

    const [classificationData, setClassificationData] = React.useState(null);
    const [classificationDataLoading, setClassificationDataLoading] = React.useState(false);

    const [freightData, setFreightData] = React.useState(null);
    const [freightDataLoading, setFreightDataLoading] = React.useState(null);

    const [badRequest, setBadRequest] = React.useState(false);
    const { niin } = useParams();

    useEffect(() => {
        Promise.all([
            getPartDetails(),
            getWsdcDetails(),
            getMoeDetails(),
            getSolicitationHistory(),
            getStockOnHand(),
            getReplacementNiins(),
            getManagementData(),
            getStockData(),
            getClassificationData(),
            getFreightDimensionData()
        ]).then();
    }, []);

    const getPartDetails = () => {
        return new Promise((resolve, reject) => {
            PartsApi.view(niin).then((response) => {
                setPart(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 1");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setDataLoading(false);
            });
        });
    }

    const getWsdcDetails = () => {
        setWsdcDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchWsdc(niin).then((response) => {
                setWsdcDetailRows(response.data);
                resolve(response.data);
            })
            .catch((err) => {
                console.log("Bad Request 2");
                setBadRequest(true);
                reject(err);
            })
            .finally(() => {
                setWsdcDetailRowsLoading(false);
            });
        });
    }

    const getMoeDetails = () => {
        setMoeDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchMoe(niin).then((response) => {
                setMoeDetailRows(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 3");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setMoeDetailRowsLoading(false);
                });
        });
    }

    const getSolicitationHistory = () => {
        setSolicitationHistoryRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchSolicitationHistory(niin).then((response) => {
                setSolicitationHistoryRows(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 4");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setSolicitationHistoryRowsLoading(false);
                });
        });
    }

    const getStockOnHand = () => {
        setStockOnHandRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchStockOnHand(niin).then((response) => {
                setStockOnHandRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 5");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setStockOnHandRowsLoading(false);
            });
        })
    }

    const getReplacementNiins = () => {
        setReplacementNiinRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchReplacements(niin, true).then((response) => {
                setReplacementNiinRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 6");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setReplacementNiinRowsLoading(false);
            });
        })
    }

    const getManagementData = () => {
        setManagementDataRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchManagement(niin).then((response) => {
                setManagementDataRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 7");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setManagementDataRowsLoading(false);
            });
        })
    }

    const getStockData = () => {
        setStockDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchStockData(niin).then((response) => {
                setStockData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 8");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setStockDataLoading(false);
            });
        })
    }

    const getClassificationData = () => {
        setClassificationDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchItemClassification(niin).then((response) => {
                setClassificationData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 9");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setClassificationDataLoading(false);
            });
        })
    }

    const getFreightDimensionData = () => {
        setFreightDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchFreightAndDimensions(niin).then((response) => {
                setFreightData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 10");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setFreightDataLoading(false);
            });
        })
    }

    //const partSourceTabs = [
    //    { key: 'Approved', header: 'Approved' },
    //    { key: 'ProcHist', header: 'Procurement Hist.' },
    //    { key: 'PID', header: 'PID' }
    //]

    //const [currentPartSourceTab, setCurrentPartSourceTab] = React.useState(localStorage.getItem('currentTab') || partSourceTabs[0].key);

    //const handlePartSourceTabChange = (tab) => setCurrentPartSourceTab(tab);

    const partSourceColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => alert("Company Detail page coming soon...")}>{row.cage_code}</a>
            </>
        },

        {
            name: 'Part Number',
            selector: row => row.part_number ?? "N/A",
        },

        {
            name: 'RNCC/RNVC',
            selector: row => (row.rncc ? row.rncc : 'N/A') + ',  ' + (row.rnvc ? row.rnvc : 'N/A'),
        },

        {
            name: 'Type',
            selector: row => row.type,
        },

        {
            name: 'Date',
            selector: row => row.date
        },

        {
            name: 'Market Capture',
            selector: row => '% ' + (row.win_percentage * 100).toFixed(2).toString(),
            sortable: true
        }
    ];

    const procurementHistoryColumns = [
        {
            name: 'Contract Number',
            selector: row => row.contract_number ?? "N/A",
        },
        {
            name: 'Reference Number',
            selector: row => row.referenced_piid
        },
        {
            name: 'Awardee',
            selector: row => row.cage ?? "N/A",
        },
        {
            name: 'Date',
            selector: row => row.award_date ?? "N/A",
        },
        {
            name: 'Total Price',
            selector: row => (row.total_price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A")
        },
        {
            name: 'Qty',
            selector: row => (row.order_qty ?? "N/A") + " " + (row.unit ?? "N/A"),
        },
        {
            name: 'Unit Price',
            selector: row => (row.netprice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Price Reason',
            selector: row => row.price_reason
        }
    ]

    const customStylesDark = {
        table: {
            style: {
                fontSize: '12px'
            }
        },
        header: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        headRow: {

            style: {
                padding: "6px 8px 6px 8px",
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        rows: {
            style: {
                padding: "6px 8px 6px 8px",
                '&:nth-of-type(even)': {
                    color: 'white',
                    backgroundColor: '#1D2839'
                },
                '&:nth-of-type(odd)': {
                    color: 'white',
                    backgroundColor: '#39445C'
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
                borderTop: '1px solid white'
            }
        }
    };

    const customStylesLight = {

        header: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        rows: {
            style: {
                '&:nth-of-type(even)': {
                    color: 'black',
                    backgroundColor: '#e8e8e8'
                },
                '&:nth-of-type(odd)': {
                    color: 'black',
                    backgroundColor: 'white'
                },
            },
        }
    };

    const renderBody = () => {
        return (
            <>
                <PageHeader pageName={"Part Detail"} />

                <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                    <div className="m-0 p-0 col-11 mb-4 card-element" id="graph-bounding-box">
                        <div className="card">

                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>{part.nomenclature}</span>
                                </h5>
                            </div>

                            <div className="card-body" style={{ backgroundColor: '#1D2839' }}>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>FSC:</b></p>
                                            <div className="ml-1">{part.fsc}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>NIIN:</b></p>
                                            <div className="ml-1">{part.niin}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Budget Code:</b></p>
                                            <div className="ml-1">{part.budget}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>ERRC:</b></p>
                                            <div className="ml-1">{part.errc}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <p><b>Replacement:</b></p>
                                            <a className="ml-1" style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => routeChange(part.replacement_niin)}>{part.replacement_niin}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12">
                        
                        <div className="row">

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Sources</span>
                                                </h5>
                                            </div>
                                        </div>
                                        {/*<Tabs tabSet={partSourceTabs} currentTab={currentPartSourceTab} onTabSelect={handlePartSourceTabChange} />*/}
                                        <DataTable
                                            columns={partSourceColumns}
                                            data={part.part_sources}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>MOE Rules</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !moeDetailRowsLoading ?
                                                <MoeTable rows={moeDetailRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement History</span>
                                                </h5>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={procurementHistoryColumns}
                                            data={part.proc_hist}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Classification Info</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !classificationDataLoading ?
                                                        classificationData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Classification:</b></p>
                                                                                <div className="ml-1">{classificationData.classification_unified_cages}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Classification Date:</b></p>
                                                                                <div className="ml-1">{classificationData.unified_cages_date}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>MCRL Classification:</b></p>
                                                                                <div className="ml-1">{classificationData.mcrl_unified_classification}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>MCRL Class. Date:</b></p>
                                                                                <div className="ml-1">{classificationData.mcrl_unified_date}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>QPL Controlled:</b></p>
                                                                                {
                                                                                    classificationData.qpl_controlled ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Source Controlled:</b></p>
                                                                                {
                                                                                    classificationData.source_controlled ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Design Controlled:</b></p>
                                                                                {
                                                                                    classificationData.design_controlled ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Data Rights:</b></p>
                                                                                {
                                                                                    classificationData.data_rights ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Open Tech Data:</b></p>
                                                                                {
                                                                                    classificationData.open_tech_data ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unavailable Data:</b></p>
                                                                                {
                                                                                    classificationData.unavailable_data ?
                                                                                        <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                        : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Freight and Dimensions</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !freightDataLoading ?
                                                        freightData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>DSS Weight:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.dss_weight) + ' pounds'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>DSS Cube:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.dss_cube) + ' cubic feet'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unit Pack Weight:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.unit_pack_weight) + ' pounds'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unit Pack Cube:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.unit_pack_cube) + ' cubic feet'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>NMFC:</b></p>
                                                                                <div className="ml-1">{freightData.nmfc + ' - ' + freightData.nmfc_sub}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>NMF Desc.:</b></p>
                                                                                <div className="ml-1">{freightData.nmf_desc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Activity Code:</b></p>
                                                                                <div className="ml-1">{freightData.acty_cd ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>HazMat Code:</b></p>
                                                                                <div className="ml-1">{freightData.hmc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Special Handling Code:</b></p>
                                                                                <div className="ml-1">{freightData.shc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Uniform Freight Class. Code:</b></p>
                                                                                <div className="ml-1">{freightData.ufc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>



                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Management</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !managementDataRowsLoading ?
                                                <ManagementTable rows={managementDataRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-3">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Stock Details</span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <ul class="nav nav-pills mb-3 float-right" id="pills-tab" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link active" id="pills-soh-tab" data-toggle="pill" data-target="#pills-soh" type="button" role="tab" aria-controls="pills-soh" aria-selected="true">Stock On Hand</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="pills-sd-tab" data-toggle="pill" data-target="#pills-sd" type="button" role="tab" aria-controls="pills-sd" aria-selected="false">Details</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade show active" id="pills-soh" role="tabpanel" aria-labelledby="pills-soh-tab">
                                                        {
                                                            !stockOnHandRowsLoading ?
                                                                <PartStockOnHand history={stockOnHandRows} />
                                                                : <div className="row m-0 p-4" style={{
                                                                    textAlign: 'center'
                                                                }}>
                                                                    <div className="col">
                                                                        <BeatLoader color={"#BF663F"} />
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-sd" role="tabpanel" aria-labelledby="pills-sd-tab">
                                                        {
                                                            !stockDataLoading ?
                                                                stockData != null ?
                                                                    <>
                                                                        <div className="container m-3">
                                                                            <div className="row justify-content-center">
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Annual Consumption:</b></p>
                                                                                        <div className="ml-1">{CurrencyFormatter.format(stockData.annual_consumption)}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Current Stock Date:</b></p>
                                                                                        <div className="ml-1">{stockData.current_stock_date}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <div className="row justify-content-center">
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Current Stock Out:</b></p>
                                                                                        {
                                                                                            stockData.current_stock_out ?
                                                                                                <div className="ml-1"><FontAwesomeIcon icon={faCheck} /></div>
                                                                                                : <div className="ml-1"><FontAwesomeIcon icon={faXmark} /></div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Back Order Quantity:</b></p>
                                                                                        <div className="ml-1">{NumberFormatter.format(stockData.back_order_qtys)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Administrative Lead Time:</b></p>
                                                                                        <div className="ml-1">{stockData.alt.toString() + ' days'}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <p><b>Production Lead Time:</b></p>
                                                                                        <div className="ml-1">{stockData.plt.toString() + ' days'}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : <div className="row m-0 p-4" style={{
                                                                        textAlign: 'center'
                                                                    }}>
                                                                        <div className="col">
                                                                            No Data Loaded.
                                                                        </div>
                                                                    </div>
                                                                : <div className="row m-0 p-4" style={{
                                                                    textAlign: 'center'
                                                                }}>
                                                                    <div className="col">
                                                                        <BeatLoader color={"#BF663F"} />
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-xl-5 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Replaced NIIN's</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !replacementNiinRowsLoading && replacementNiinRows.length > 0 ?
                                                <ReplacedNiinsTable rows={replacementNiinRows} style={customStylesDark} />
                                                : replacementNiinRowsLoading ?
                                                    <div className="row m-0 p-4" style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <div className="col">
                                                            <BeatLoader color={"#BF663F"} />
                                                        </div>
                                                    </div>
                                                    : <div className="row m-0 p-4" style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <div className="col">
                                                            No records to display.
                                                        </div>
                                                    </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-7 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white'}}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-3">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Weapon Systems</span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <ul class="nav nav-pills mb-3 float-right" id="pills-tab" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link active" id="pills-wscn-tab" data-toggle="pill" data-target="#pills-wscn" type="button" role="tab" aria-controls="pills-wscn" aria-selected="true">Common Names</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="pills-wsfull-tab" data-toggle="pill" data-target="#pills-wsfull" type="button" role="tab" aria-controls="pills-wsfull" aria-selected="false">Full</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active" id="pills-wscn" role="tabpanel" aria-labelledby="pills-wscn-tab">
                                                {
                                                    !wsdcDetailRowsLoading ?
                                                        <WsdcCommonNameTable rows={wsdcDetailRows} style={customStylesDark} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div class="tab-pane fade" id="pills-wsfull" role="tabpanel" aria-labelledby="pills-wsfull-tab">
                                                {
                                                    !wsdcDetailRowsLoading ?
                                                        <WsdcTable rows={wsdcDetailRows} style={customStylesDark} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Solicitation History</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !solicitationHistoryRowsLoading ?
                                                <SolicitationHistoryTable rows={solicitationHistoryRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br/>
                    {/*{*/}
                    {/*    stockOnHandRows.length > 0 ?*/}
                    {/*        <>*/}
                    {/*            <div className="row mt-3" style={{ width: "100%" }}>*/}
                    {/*                <div className="m-0 ml-3 p-2 pl-4 pr-4 col-11 col-xl-8 color1 card-element">*/}
                    {/*                    <div style={{ borderBottom: '1px solid white' }}>*/}
                    {/*                        <div className="card" style={{ border: '0' }}>*/}

                    {/*                            <div className="card-header p-0">*/}
                    {/*                                <div className="row p-0 mt-1 justify-content-between text-right">*/}
                    {/*                                    <div className="col-9 text-left">*/}
                    {/*                                        <h4>*/}
                    {/*                                            Stock On Hand*/}
                    {/*                                        </h4>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    {/*                    <div>*/}
                    {/*                            <PartStockOnHand history={stockOnHandRows} />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-12 col-xl-3 m-0">*/}
                    {/*                    <div className="row p-4 pt-4 d-flex justify-content-between">*/}
                    {/*                        <div className="col-12 pt-2">*/}
                    {/*                            <p*/}
                    {/*                                className=""*/}
                    {/*                                style={{*/}
                    {/*                                    letterSpacing: '3px',*/}
                    {/*                                    fontSize: '13px',*/}
                    {/*                                    opacity: '90%',*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                Sort By Category:*/}
                    {/*                            </p>*/}
                    {/*                            <div className="row justify-content-between">*/}
                    {/*                                <div className="col-12">*/}
                    {/*                                    <label className="btn btn-primary chart-cat-btn m-1 pt-1 pb-1">*/}
                    {/*                                        <input type="radio" name="options" id="option1" disabled /> Market*/}
                    {/*                                    </label>*/}
                    {/*                                    <label className="btn btn-primary chart-cat-btn m-1 pt-1 pb-1">*/}
                    {/*                                        <input type="radio" name="options" id="option2" disabled /> Forecast*/}
                    {/*                                    </label>*/}
                    {/*                                    <label className="btn btn-primary chart-cat-btn m-1 pt-1 pb-1">*/}
                    {/*                                        <input type="radio" name="options" id="option3" disabled /> Capture*/}
                    {/*                                    </label>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}

                    {/*                        <div className="col-12 mt-1">*/}
                    {/*                            <button className="btn chart-select-btn" style={{ borderTop: '1px solid grey' }}>*/}
                    {/*                                Stock On Hand Over Time*/}
                    {/*                            </button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-12 mt-1">*/}
                    {/*                            <button className="btn chart-select-btn" style={{}} disabled >*/}
                    {/*                                Chart Description 2*/}
                    {/*                            </button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-12 mt-1">*/}
                    {/*                            <button className="btn chart-select-btn" style={{}} disabled >*/}
                    {/*                                Chart Description 3*/}
                    {/*                            </button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-12 mt-1">*/}
                    {/*                            <button className="btn chart-select-btn" style={{}} disabled >*/}
                    {/*                                Chart Description 4*/}
                    {/*                            </button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-12 mt-1">*/}
                    {/*                            <button className="btn chart-select-btn" style={{}} disabled >*/}
                    {/*                                Chart Description 5*/}
                    {/*                            </button>*/}
                    {/*                        </div>*/}


                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*        : null*/}
                    {/*}*/}

                </div>


            </>
        )
    };

    return (
        <>
            <div>
                {
                    dataLoading
                        ? (
                            <div className="progress-container">
                                <div className="progress-bar"></div>
                            </div>
                        )
                        : renderBody()
                }
            </div>
        </>
    );
}