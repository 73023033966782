import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const MoeTable = ({ rows, style }) => {

    const moeColumns = [
        {
            name: 'Moe Rule',
            selector: row => row.moe_rule,
        },
        {
            name: 'Moe Code',
            selector: row => row.moe_code,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.moe_description}>{row.moe_code}</a>
            </>
        },
        {
            name: 'PICA',
            selector: row => row.pica,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.pica_description}>{row.pica}</a>
            </>
        },
        {
            name: 'Amc',
            selector: row => row.amc,
        },
        {
            name: 'Amsc',
            selector: row => row.amsc,
        },
        {
            name: 'Date Assigned',
            selector: row => row.date_assigned,
        }
    ]

    return (
        <>
            <DataTable
                columns={moeColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}