import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { PartsApi } from '../../api/PartsApi';

export const NiinRow = ({ ix, niin, name, fsc }) => {

    const [niinHover, setNiinHover] = React.useState(false);
    const [niinDetails, setNiinDetails] = React.useState(null);
    const niinLoadingRef = React.useRef(false);

    const onHover = (niin) => {
        niinLoadingRef.current = true;

        if (niinDetails != null) {
            setNiinHover(true);
            return;
        };

        setTimeout(function () {
            PartsApi.view(niin).then((response) => {
                setNiinDetails({
                    info: response.data
                });

                if (niinLoadingRef.current) {
                    setNiinHover(true);
                }
               
            }).catch((err) => {

            }).finally();
        }, 300);
    }

    const onOut = () => {
        niinLoadingRef.current = false;
        setNiinHover(false);
    }

    return (
        <>
            <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer', color: 'white' }}
                id={"niinCell" + ix}
                onMouseOver={() => onHover(niin)}
                onMouseOut={() => onOut()}
                href={`${window.location.origin}/usg/parts/search/view/${niin}`}
                target='_blank'
                rel="noopener noreferrer"
            >
                {niin}
            </a>
            <Popover placement="right" isOpen={niinHover} target={"niinCell"+ix}>
                <PopoverHeader>{niinDetails?.info.nomenclature ?? ""}</PopoverHeader>
                <PopoverBody>
                    <div className="container" style={{ maxWidth: '400px' }}>
                        <div className="row">
                            Fsc: { niinDetails?.info.fsc ?? ""}
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </>
    )
}